.NewsletterWrapper{
    padding: 30px 0;
    
    .Newsletter{
        padding: 0;
        
        .title{
            font-size: 18px;
            color: #aaaab3;
        }

        .success{
            position: absolute;
            background-color: #1ec012;
            padding: 10px 15px;
            color: #ffffff;
            border-radius: 3px;
        }

        input{
            padding: 8px 15px;
            width: 300px;
            border-radius: 3px 0 0 3px;
            border: 2px solid transparent;
            background: rgba(255, 255, 255, 0.5);
            outline: #1ec012;
            color: #ffffff;
        }
        
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #ffffff;
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #ffffff;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: #ffffff;
          }

        .cac_btn_arrow{
            padding: 8px;
            border: 2px solid #1ec012;
            background-color: #1ec012;
            transition: all .15s linear;
            color: #ffffff;
            border-radius: 0 3px 3px 0;
            width: 60px;
            
            &:hover{
                background-color: #2d9725;
                border: 2px solid #2d9725;
            }
        }
        
        .harrisia_btn_arrow{
            padding: 8px;
            border: 2px solid #e2930a;
            background-color: #e2930a;
            transition: all .15s linear;
            color: #ffffff;
            border-radius: 0 3px 3px 0;
            width: 60px;

            &:hover{
                background-color: #b3770f;
                border: 2px solid #b3770f;
            }
        }
        
        .cbs_btn_arrow{
            padding: 8px;
            border: 2px solid #40aaf2;
            background-color: #40aaf2;
            transition: all .15s linear;
            color: #ffffff;
            border-radius: 0 3px 3px 0;
            width: 60px;

            &:hover{
                background-color: #238dd3;
                border: 2px solid #238dd3;
            }
        }

    }
    
    @media (max-width: 996px){
        padding: 15px;

        .Newsletter{
            padding: 15px;

            input{
                width: calc(100% - 60px) !important;
            }
    
            button{
                width: 60px !important;
            }
        }
    }
}