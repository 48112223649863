.FooterWrapper{

    .Footer{
        padding-top: 70px;

        .f--boxes{
            padding: 15px;
            color: #d6d1d1;
    
            .logo{
                height: 50px;
            }

            .f--box--title{
                font-family: 'PROXIMANOVA BOLD';
                font-size: 26px;
                color: #ffffff;
            }

            ul{
                list-style: none;
                padding: 0;
                
                li{
                    a{
                        color: #d6d1d1;
                        font-size: 18px;
                    }
                }
            }
        }
        
        .footer--bottom{
            margin-top: 70px;
            border-top: 1px solid rgba(255, 255,255, 0.3);

            p{
                text-align: center;
                color: #aaaab3;
                margin: 0;
                padding: 15px;
            }
        }
    }

}