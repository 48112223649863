.PopUp_1313{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999999;

    .shadow{
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .modal__open{
        width: 500px;
        background-color: #ffffff;
        z-index: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% - 250px);
        padding: 15px;
        text-align: center;
        border-radius: 3px;
        animation: move__down 1s forwards;

        .modal--title{
            font-size: 26px;
            font-family: 'PROXIMANOVA BOLD';
            margin: 0;
            color: #000000;
        }

        span{
            color: #000000;
            background-color: #ffffff;
            border-radius: 50%;
            padding: 3px 8px;
            position: absolute;
            top: -11px;
            right: -11px;
            cursor: pointer;
            transition: all .15s linear;

            &:hover{
                background-color: #ccc4c4;
            }
        }

        @media(max-width: 560px){
            width: calc(100% - 30px);
            left: 15px;
        }
    }

    @keyframes move__down {
        from{
            top: 0;
        }
        to{
            top: 50%;
        }
    }
}