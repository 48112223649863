.c-f{
  justify-content: center;
  
  .Navbar--Fixed{
      position: fixed;
      z-index: 2;
  }

  .Navbar{
    display: flex;
    align-items: center;
    padding: .5rem 0rem;
    min-height: 70px;
    
    .nav--logo{
      .nav--img{
        height: 50px;
      }
    }

    .nav--menu{
      margin-left: auto;

      ul{
        list-style: none;
        padding: 0;
        display: flex;
        margin: 0;
        font-family: 'PROXIMANOVA BOLD';

        li{
          height: 100%;

            a{
                display: block;
                padding: 5px 20px;
                color: #000000;
                text-transform: uppercase;
                text-decoration: none;
                border: 1px solid transparent;
                transition: all .15s linear;

            }

            .cac_nav-item:hover{
                border: 1px solid #1ec012;
                color: #1ec012 !important;
            }
            
            .harrisia_nav-item:hover{
                border: 1px solid #e2930a;
                color: #e2930a !important;
            }
        
            a.cac_active:not(.contact--us){
                border: 1px solid #1ec012;
                color: #1ec012 !important;
            }
            
            a.harrisia_active:not(.contact--us){
                border: 1px solid #e2930a !important;
                color: #e2930a !important;
            }
            
            a.cbs_active:not(.contact--us){
                border: 1px solid #40aaf2;
                color: #40aaf2 !important;
            }

            a.contact--us--cac{
                background-color: #1ec012;
                font-size: 14px;
                padding: 6.5px 25px;
                border-radius: 3px;

                &:hover{
                    background-color: #22aa19;
                    color: #ffffff !important;
                }
            }
            
            a.contact--us--harrisia{
                background-color: #e2930a;
                font-size: 14px;
                padding: 6.5px 25px;

                &:hover{
                    background-color: #c58516;
                    color: #ffffff !important;
                }
            }

            a.contact--us--cbs{
                background-color: #40aaf2;
                font-size: 14px;
                padding: 6.5px 25px;

                &:hover{
                    background-color: #40aaf2;
                    color: #ffffff !important;
                }
            }
        }
    }

      @media (max-width: 996px){
        display: none;
      }
    }

    .toggle--button{
      padding: 13px 12px;
      background-color: transparent;
      border: none;
      outline: none;
      position: fixed;
      top: 15px;
      right: 15px;
      outline: none;
      z-index: 200;
      display: none;
  
      @media (max-width: 996px){
          display: block;
      }
  
        span{
            display: block;
            height: 5px;
            width: 30px;
            margin-top: 2px;
            margin-bottom: 2px;
    
            &:nth-child(3){
                width: 15px;
            }
        }
    }

    .cac_btn--gr{
      transition: all .15s linear;

      span{
        background-color: #1ec012;
      }
    }
    
    .harrisia_btn--gr{
      transition: all .15s linear;

      span{
        background-color: #e2930a;
      }
    }

    .cbs_btn--gr{
      transition: all .15s linear;

      span{
        background-color: #40aaf2;
      }
    }

    .btn--wh{
      transition: all .15s linear;
      span{
        background-color: #ffffff;
      }
    }
    
}

  
  .bg-w{
    transition: all .15s linear;
    background-color: #ffffff;
    border-bottom: 3px solid #f1f1f1;
    z-index: 10000;
    top: 0;

    @media (max-width: 996px){
      background-color: rgba(255,255,255,0.9);
    }

    
    .languages{
      position: absolute;
      right: 15px;
      top: 20px;
      font-family: 'PROXIMANOVA BOLD';

        .lang__button{
            background-color: transparent;
            padding: 0;
            border: none;
            outline: none;
            margin-right: 10px;
            opacity: 0.2;
            font-size: 12px;
        }
    
        .lang__active{
          opacity: 1;
          border-bottom: 2px solid #000000;
        }

        @media (max-width: 996px){
            display: none;
        }

    }

    .contact--us--cac, .contact--us--harrisia, .contact--us--cbs{
        color: #ffffff !important;

        &:hover{
            color: #ffffff;
        }
    }
  }

  .bg_wno{
    transition: all .15s linear;
    background-color: #ffffff;
    border-bottom: 3px solid #f1f1f1;
    z-index: 1000;

    .languages{
        @media (max-width: 996px){
            display: none;
        }
    }
    
  }

  .bg-t{
    transition: all .15s linear;
    background-color: transparent;

    .Navbar .nav--menu ul li a{
      color: #ffffff;
    }

    .languages{
      position: absolute;
      right: 15px;
      top: 20px;

      .lang__button{
        background-color: transparent;
        padding: 0;
        border: none;
        outline: none;
        margin-right: 10px;
        opacity: 0.2;
        color: #ffffff !important;
        font-size: 12px;
      }

      .lang__active{
        opacity: 1;
        border-bottom: 2px solid #ffffff;
      }

      @media (max-width: 996px){
        display: none;
      }

    }
  }
}

.Dropdown{
  width: 100%;
  left: 0;
  position: fixed;
  background-color: #ffffff;
  top: -110%;
  transition: all .7s linear;
  padding: 60px 30px;
  z-index: 120;

  .dropdown--image{
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: 15px;
    max-height: 300px;
  }

  ul{
    padding: 0;
    list-style: none;
    margin: 0;
    font-family: 'PROXIMANOVA BOLD';

    li{

      a{
        display: block;
        padding: 5px;
        text-transform: uppercase;
        color: #000000;
        text-decoration: none;
      }

      .cac__dropdown__item:hover{
          color: #1ec012;
      }
    
      .harrisia__dropdown__item:hover{
          color: #e2930a;
      }

      .cbs__dropdown__item:hover{
          color: #40aaf2;
      }

      .cac__dropdown__item_act{
          color: #1ec012;
      }
    
      .harrisia__dropdown__item_act{
          color: #e2930a;
      }

      .cbs__dropdown__item_act{
          color: #40aaf2;
      }

    }

    .active{
      color: #40aaf2;
    }
  }
}

.dr__down{
  top: 72.5px;
}

.Sidebar{
  position: fixed;
  top: 68px;
  left: -100%;
  width: 100%;
  height: calc(100vh - 68px);
  background-color: #ffffff;
  transition: all .4s linear;
  z-index: 120;
  overflow-x: auto;

  .search__here{
    padding: 15px;

    .search__icon{
      position: absolute;
      top: 33px;
      left: 26px;
    }

    input{
      background: #f1f3f4;
      border: 1px solid transparent;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      height: 55px;
      outline: none;
      padding-left: 35px;
      margin-right: 10px;
      width: calc(100% - 60px);
      transition: all .15s linear;
      
      &:focus{
          // box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
          border: 2px solid #f1f1f1;
          background-color: #fff;
      }
    }
    
    button{
      color: #ffffff;
      padding: 5px 15px;
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }
    
    .cac__button{
      border: 1px solid #22d816;
      background-color: #22d816;
    }

    .harrisia__button{
      border: 1px solid #e2930a;
      background-color: #e2930a;
    }

    .cbs__button{
      border: 1px solid #40aaf2;
      background-color: #40aaf2;
    }
    
  }

  .languages{
    padding: 15px;
    font-family: 'PROXIMANOVA BOLD';

    .lang__button{
      background-color: #ffffff;
      padding: 0;
      border: none;
      outline: none;
      margin-right: 10px;
      opacity: 0.6;
      
    }

    .lang__active{
      opacity: 1;
      border-bottom: 2px solid #000000;
    }
  }

  ul{
    padding: 15px 0 15px;
    margin: 0;
    list-style: none;
    text-transform: uppercase;
    font-family: 'PROXIMANOVA BOLD';

    li{
        position: relative;

        a{
            display: block;
            padding: 8px 15px;
            color: #000000;
            text-decoration: none;
        }
        
        i{
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            padding: 13px 30px;
            cursor: pointer;
        }


      .cac__active__item{
        color: #1ec012;
      }
      
      .harrisia__active__item{
        color: #e2930a;
      }

      .cbs__active__item{
        color: #40aaf2;
      }

      ul{
        padding: 0px 15px;
        font-size: 14px;
        max-width: calc(100% - 50px);
      }
    }
  }
}

.sr__left{
  left: 0;
}