body{
    font-family: 'PROXIMANOVA REGULAR';
}

.app--body{
  min-height: 600px;
}

.spinner__wrapper{
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
}

.spinner {
  position: absolute;
  top: 46%;
  left: calc(50% - 35px);
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner__wrapper__cac .spinner > div{
  background-color: #1ec012;
}

.spinner__wrapper__harrisia .spinner > div{
  background-color: #e2930a;
}

.spinner__wrapper__cbs .spinner > div{
  background-color: #40aaf2;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.cac_ScrollUpButton__Container{
  width: 50px !important;
  height: 50px !important;
  padding: 12px 10px !important;
  outline: none !important;
  background-color: #1ec012 !important;
  transition: all .15s linear !important;
  right: 15px !important;
  bottom: 15px !important;
  
  &:hover{
    background-color: #1b8f13 !important;
  }
}

.harrisia_ScrollUpButton__Container{
  width: 50px !important;
  height: 50px !important;
  padding: 12px 10px !important;
  outline: none !important;
  background-color: #e2930a !important;
  transition: all .15s linear !important;
  right: 15px !important;
  bottom: 15px !important;
  
  &:hover{
    background-color: #b3770f !important;
  }
}

.cbs_ScrollUpButton__Container{
  width: 50px !important;
  height: 50px !important;
  padding: 12px 10px !important;
  outline: none !important;
  background-color: #40aaf2 !important;
  transition: all .15s linear !important;
  right: 15px !important;
  bottom: 15px !important;
  
  &:hover{
    background-color: #238dd3 !important;
  }
}
